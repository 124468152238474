<template>
  <div class="main_div_admin_dashbord">
    <div
      id="myTopnav"
      ref="myTopnav"
      class="topnav"
    >
      <a

        @click="clickNavBtn('/MembersList')"
      >{{ $t("memberList.memberManagement") }}</a>
      <a
        :class="computedStatisticClass"
        @click="clickNavBtn('/Statistics')"
      >{{ $t("memberList.statisticalManagement") }} </a>
      <a
        class="log_out_admin_dashbord"
        @click="logoutAdmin"
      >{{
        $t("memberList.logout")
      }}</a>
    </div>
    <div class="main_admin_dashbord_new">
      <div class="main_admin_dashbord_new_first">
        <ul>
          <li
            :class="`member_li_item ${statisticType === 'signUp' ? 'active' : ''}`"
            @click="clickSideNav('signUp')"
          >
            <!-- <a>{{ $t("memberList.title") }}</a> -->
            <a>회원가입 통계</a>
          </li>
          <li
            :class="`member_li_item ${statisticType === 'login' ? 'active' : ''}`"
            @click="clickSideNav('login')"
          >
            <a>회원로그인 통계</a>
          </li>
        </ul>
      </div>
      <statistics-container
        :statistic-type="statisticType"
        :start-date="startDate"
        :start-menu="startMenu"
        :end-date="endDate"
        :end-menu="endMenu"
        :filter-date-textforall="filterDateTextforall"
        :all-count="allCount"
        :to-day-count="toDayCount"
        :term-count="termCount"
        @onchangeStartMenu="onchangeStartMenu"
        @onchangeStartDate="onchangeStartDate"
        @onchangeEndMenu="onchangeEndMenu"
        @onchangeEndDate="onchangeEndDate"
        @onchangeFilterDateTextforall="onchangeFilterDateTextforall"
        @filterDate="filterDate"
      />
    </div>
  </div>
</template>

<script>
  import '@/assets/css/style.css'
  import '@/assets/jquery/jquery.min.js'
  import '@/assets/google-font/stylesheet.css'
  import MemberDataService from '@/services/MemberDataService'
  import StatisticsDataService from '@/services/StatisticsDataService'
  import StatisticsContainer from '@/components/statistics/StatisticsContainer.vue'
  export default {
    name: 'GileadHCPAdminStatisticsComponent',
    components: {
      StatisticsContainer,
    },
    data () {
      return {
        startMenu: false,
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endMenu: false,
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        filterDateTextforall: '',

        allCount: null,
        toDayCount: null,
        termCount: null,

        statisticType: 'signUp',
        pathname: null,
      }
    },
    computed: {
      computedStatisticClass () {
        console.log(this.pathname)
        return this.pathname === '/Statistics' ? 'active' : ''
      },
    },
    watch: {
      $route (to, form) {
        if (to.path !== form.path) this.changeRoute(to.name)
      },
    },
    mounted () {
      this.changeRoute(window.location.pathname)
      // 조회 로직 추가 (분기처리 필요함 가입통계, 로그인 통계)
      this.getStatisticsData(this.statisticType)
    },

    methods: {
      clickSideNav (type) {
        this.statisticType = type
        // 타입에 맞게 조회
        this.getStatisticsData(this.statisticType)
      },
      bindSignUpCount ({ allSignUpCount, todaySignUpCount, termSignUpCount }) {
        this.allCount = allSignUpCount
        this.toDayCount = todaySignUpCount
        this.termCount = termSignUpCount
      },
      bindLogInCount ({ allLogInCount, todayLogInCount, termLogInCount }) {
        this.allCount = allLogInCount
        this.toDayCount = todayLogInCount
        this.termCount = termLogInCount
      },
      getStatisticsData (type) {
        if (!['signUp', 'login'].includes(type)) return
        var data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if (this.filterDateTextforall === 'all') {
          data.startDate = ''
          data.endDate = ''
        }
        // console.log('Start Date : ' + JSON.stringify(data))
        if (type === 'signUp') {
          StatisticsDataService.getSignUpCount(data)
            .then((response) => {
              // this.filterDateTextforall = null
              // this.filteredSubscribers = response.data.length
              // this.members = response.data.map(this.getDisplayMember);
              const [result] = response.data
              // console.log(result)
              this.bindSignUpCount(result)
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        } else {
          StatisticsDataService.getLoginCount(data)
            .then((response) => {
              // this.filterDateTextforall = null
              // this.filteredSubscribers = response.data.length
              // this.members = response.data.map(this.getDisplayMember);
              const [result] = response.data
              // console.log(result)
              this.bindLogInCount(result)
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        }
      },
      clickNavBtn (path) {
        if (window.location.pathname === path) return
        this.$router.push(path)
      },
      onchangeStartMenu (value) {
        this.startMenu = value
      },
      onchangeStartDate (value) {
        console.log('onchangeStartDate')
        this.startDate = value
        this.getStatisticsData(this.statisticType)
      },
      onchangeEndMenu (value) {
        this.endMenu = value
      },
      onchangeEndDate (value) {
        console.log('onchangeEndDate')
        this.endDate = value
        this.getStatisticsData(this.statisticType)
      },
      onchangeFilterDateTextforall (value) {
        this.computedFilterDateTextforall = value
      },
      endDateValidation () {
        if (Date.parse(this.endDate) < Date.parse(this.startDate)) {
          this.SnackbarShow('endDateValidation')
          return false
        }
      },
      filterDate (filterDateText) {
        console.log('onchangeFilterDate')
        if (filterDateText == 'today') {
          // console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString())
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1week') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setDate(this.startDate.getDate() - 7)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          // console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1month') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 1)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          // console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '6month') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 6)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          // console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1year') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setFullYear(this.startDate.getFullYear() - 1)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          // console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == 'all') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.filterDateTextforall = 'all'
        }
        this.getStatisticsData(this.statisticType)
      },
      logoutAdmin () {
        MemberDataService.logoutAdmin()
          .then((response) => {
            console.log('Logout')
            this.currentMember = ''
            localStorage.clear()
            this.$router.push('/app/sessions/admin')
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },
      changeRoute (pathname) {
        if (['/MembersList', '/Statistics'].includes(pathname)) {
          this.pathname = pathname
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
// .mebmber_li_item a {
//   color: black !important;
// }

.theme--light.v-btn.v-btn--has-bg {
  background-color: white;
  // color: black !important;
}

.topnav {
  background-color: #D9D9D9;
  overflow: hidden;
  height: 48px;
}

.topnav a {
  float: left;
  display: block;
  // color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
  // font-weight: 600;
}

.topnav .icon {
  display: none;
}

.log_out_admin_dashbord {
  float: right !important;
}

@media screen and (max-width: 768px) {
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mebmber_li_item {
    margin-top: 0px !important;
  }

  .log_out_admin_dashbord {
    float: left !important;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav {
    height: auto !important;
  }

  .main_admin_dashbord_new_first {
    width: 100% !important;
    height: auto !important;
  }

  .main_admin_dashbord_new_second {
    width: 100% !important;
    height: auto !important;

  }
}

.main_admin_dashbord_new_first {
  width: 17%;
  height: 100%;
  float: left;
  background-color: #F3F3F3;
}

.main_admin_dashbord_new_second {
  width: 83%;
  height: 100%;
  float: left;
}

// .mebmber_li_item {
//   list-style-type: none;
//   // font-weight: 600;
//   margin-top: 40px;
// }

a {
  text-decoration: none;
  // color: black;
}

// button.date_time_week_btngroup_button.btn.btn-outline-secondary.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
//   background-color: #5A5A5A;
//   color: white;
// }

// button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
//   background-color: #5A5A5A;
//   color: white !important;
// }

// .email_read_only_modal .v-input__control .v-input__slot {
//   background-color: #EDEDED;
// }

// .v-btn:before {
//   background-color: #ffffff !important;
// }

// .v-text-field input {
//   /* padding-top: 8px !important; */
//   margin-top: 5px !important;
// }

// /* .member_text_field_slot v-input__control v-input__slot v-text-field__slot  input{
// padding-top: 8px 0 8px !important;
// } */
// .list {
//   max-width: 750px;
// }

// .datacountClass {
//   font-family: Noto Sans;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 19px;
//   letter-spacing: 0em;
//   text-align: left;

// }

// /* .v-btn {
// min-width: 100px;
// } */

// .admin_dashbord_content_member_list {
//   /* margin-top: 3px !important; */
// }

// .v-input__prepend-outer {
//   margin-top: 0px !important;
// }

// .v-input__append-inner {
//   margin-top: 6px !important;
// }

// .v-text-field input {
//   padding-top: 3px !important;
// }

// table th span {
//   font-size: 14px !important;
//   font-family: 'Noto Sans' !important;
//   color: black;
// }

// .v-data-table-header {
//   background-color: #D9D9D9;
// }

// .admin_dashbord_content_member_list_row {
//   // margin-top: -18px !important;
//   margin-left: 5px !important;
// }

// .admin_dashbord_content_member_list_row_first {
//   margin-left: 5px !important;
// }

// .vcardaction_buttons {
//   margin: 15px 247px !important;
//   /* justify-content: center !important; */
// }

// .vcardaction_buttons_withdelete {
//   margin: 15px 174px !important;
// }

// .v_dialog_member {
//   font-size: 14px !important;
//   color: #000000 !important;
//   font-weight: 400px !important;
//   font-family: 'Noto Sans' !important;
// }

// li {
//   font-size: 14px !important;
//   color: #000000 !important;
//   font-weight: 400px !important;
//   font-family: 'Noto Sans' !important;
// }

// .approval_toggle_button {
//   /* position: absolute !important; */
//   // width: 849.31px !important;

//   background: #FFFFFF !important;
// }

// .v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
//   max-height: 90%;
// }

// .v-select__slot {
//   max-height: 36px !important;
//   display: flex !important;
//   align-items: center !important
// }

// .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
//   width: 122px;
// }

// .approval_toggle_button button {
//   width: 20% !important;
// }

// .approval_toggle_button button span {
//   // color: black;
//   word-break: break-all !important;
// }

// .v-btn__content {
//   /* word-wrap: break-word; */
// }

// .name_details_span li {
//   list-style-type: none;
// }

// @media screen and (max-width: 992px) {
//   .view_license_dialog {
//     min-width: fit-content !important;
//   }

// }

// .view_license_main_footer {
//   justify-content: center !important;
//   text-align: center;
// }

// .view_license_main_footer button {
//   background-color: #D9D9D9;
//   border-radius: 0%;
//   border: none;
//   width: 150px;
//   height: 45px;
//   color: black;
// }

// ul.view_license_dialog .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
//   min-width: 230px;
//   margin: 5px;
//   /* border: 1px solid black !important; */
//   // border-radius: 6px;
// }

// .approval_toggle_button.v-item-group.theme--light.v-btn-toggle {
//   display: block;
// }

// @media only screen and (max-width: 992px) {
//   .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
//     /* changed in button group */
//     // display: block;
//   }

//   .v-application .d-inline-block {
//     display: inline-block !important;
//     word-break: break-word;
//     width: 50%;
//     float: left;
//   }

//   .v-card__text.v_dialog_member {
//     overflow-x: hidden;
//   }

//   .view_license_dialog.list-group.list-group-horizontal {
//     width: 100%;
//     float: left;
//   }
// }

// .dialog_member_row_test {
//   margin: -22px 0px !important;
// }

// @media screen and (max-width: 487px) {
//   .view_details_close_btn {
//     margin-top: 2% !important;
//   }

// }

// @media screen and (max-width: 599px) {
//   .admin_dashbord_content_member_second_list {
//     margin-top: -35px !important;
//   }

//   .inquiry_btn_button_main {
//     margin-top: -40px;
//   }

//   .dialog_member_col_test {
//     margin: 4px !important;
//     padding: 0px !important;
//   }

//   .dialog_member_row_test_down {
//     margin: 0px !important;
//     padding: 4px !important;
//   }
// }

// @media screen and (max-width: 960px) {
//   .date_time_week_btngroup_main_class {
//     margin-top: -41px !important;
//   }
// }

// @media screen and (min-width: 960px) {
//   .date_time_week_btngroup_toggle {
//     display: block !important;
//   }

//   .date_time_week_btngroup {
//     padding-top: -20px;
//   }

// }

// @media screen and (max-width: 555px) {
//   .date_time_week_btngroup_toggle {
//     display: block !important;
//   }

// }

// // .menuable__content__active{
// //   width: 122px;
// // }

// .date_time_week_btngroup_toggle {
//   // border: 1px solid black;
// }

// img {
//   vertical-align: middle;
//   border: 0;
// }

// .startdate_picker {
//   width: 290px !important;
// }

// .div_border_top {
//   border-top: 1px solid;
//   padding-top: 10px;
// }
</style>
