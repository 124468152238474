<template>
  <div class="main_admin_dashbord_new_second">
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
      rounded="pill"
      width="877.27px"
    >
      <v-row class="pr-4" align="center">
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-row column>
          <div>{{ $t(snackbar.text) }}</div>
        </v-row>
      </v-row>
      <v-btn
        v-if="snackbar.timeout === 0"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container fluid>
      <div class="row content">
        <div
          class="col-sm-12 col-md-12 col-xl-12 xol-lg-12 admin_dashbord_content"
        >
          <h5 class="fw-700">
            {{ computedTitle }}
            <!-- {{ $t("memberList.memberList") }} -->
          </h5>
          <v-row class="admin_dashbord_content_member_list_row_first">
            <v-col cols="2">
              <v-card class="pa-2" outlined tile>
                {{ computedAllcount }}
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-2" outlined tile> {{ allCount }}명 </v-card>
            </v-col>
            <v-col cols="2">
              <v-card class="pa-2" outlined tile>
                {{ computedTodayCount }}
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-2" outlined tile> {{ toDayCount }}명 </v-card>
            </v-col>
          </v-row>
          <v-row class="admin_dashbord_content_member_list_row">
            <v-col
              class="admin_dashbord_content_member_list"
              cols="12"
              md="3"
              sm="6"
            >
              <v-menu
                v-model="computedStartMenu"
                class="v_menu_start_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }" outlined>
                  <v-text-field
                    v-model="computedStartDate"
                    label=""
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    style="min-height: auto"
                    @click:append="on.click"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="computedStartDate"
                  class="startdate_picker"
                  @change="endDateValidation($event, 'startDate')"
                  @input="$emit('onchangeStartMenu', false)"
                />
              </v-menu>
            </v-col>
            <v-col
              class="
                admin_dashbord_content_member_list
                admin_dashbord_content_member_second_list
              "
              cols="12"
              md="3"
              sm="6"
            >
              <v-menu
                v-model="computedEndMenu"
                class="v_menu_start_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedEndDate"
                    label=""
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    @click:append="on.click"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="computedEndDate"
                  width="290px"
                  @change="endDateValidation($event, 'endDate')"
                  @input="$emit('onchangeEndMenu', false)"
                />
              </v-menu>
            </v-col>
            <v-col
              class="date_time_week_btngroup_main_class"
              cols="12"
              md="6"
              sm="12"
            >
              <div
                class="date_time_week_btngroup"
                aria-label="Basic outlined example"
              >
                <v-btn-toggle class="date_time_week_btngroup_toggle" mandatory>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('today')"
                  >
                    {{ $t("memberList.today") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('1week')"
                  >
                    {{ $t("memberList.week") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('1month')"
                  >
                    {{ $t("memberList.onemonth") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('6month')"
                  >
                    {{ $t("memberList.sixmonth") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('1year')"
                  >
                    {{ $t("memberList.year") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    class="date_time_week_btngroup_button"
                    @click="filterDate('all')"
                  >
                    {{ $t("memberList.all") }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
          <v-row class="admin_dashbord_content_member_list_row">
            <v-col cols="6">
              <v-card class="pa-2" outlined tile>
                <!-- 2022년 10월 15일 ~ 2022년 10월21일 가입자 현황 -->
                {{ startDate }} ~ {{ endDate }}
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-2" outlined tile> {{ termCount }}명 </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import "@/assets/css/style.css";
import "@/assets/jquery/jquery.min.js";
import "@/assets/google-font/stylesheet.css";
import { statisticTable } from "@/components/statistics/statisticsColumn";
export default {
  name: "GileadHCPAdminStatisticsContainer",
  props: {
    statisticType: {
      type: String,
      default: null,
    },
    startMenu: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    endMenu: {
      type: Boolean,
      default: false,
    },
    endDate: {
      type: String,
      default: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    filterDateTextforall: {
      type: String,
      default: "",
    },
    allCount: {
      type: Number,
      default: null,
    },
    toDayCount: {
      type: Number,
      default: null,
    },
    termCount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 2500,
        title: null,
        visible: false,
      },
    };
  },
  computed: {
    computedTitle() {
      return statisticTable[this.statisticType].title;
    },
    computedAllcount() {
      return statisticTable[this.statisticType].allCount;
    },
    computedTodayCount() {
      return statisticTable[this.statisticType].toDayCount;
    },
    computedStartMenu: {
      get() {
        return this.startMenu;
      },
      set(newValue) {
        return this.$emit("onchangeStartMenu", newValue);
      },
    },
    computedStartDate: {
      get() {
        return this.startDate;
      },
      set(newValue) {
        return this.$emit("onchangeStartDate", newValue);
      },
    },
    computedEndMenu: {
      get() {
        return this.endMenu;
      },
      set(newValue) {
        return this.$emit("onchangeEndMenu", newValue);
      },
    },
    computedEndDate: {
      get() {
        return this.endDate;
      },
      set(newValue) {
        return this.$emit("onchangeEndDate", newValue);
      },
    },
    computedFilterDateTextforall: {
      get() {
        return this.filterDateTextforall;
      },
      set(newValue) {
        return this.$emit("onchangeFilterDateTextforall", newValue);
      },
    },
  },
  mounted() {},

  methods: {
    SnackbarShow(type) {
      switch (type) {
        case "endDateValidation":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberList.endDateValidation",
            visible: true,
          };
          break;
      }
    },
    endDateValidation(value, type) {
      if (type === "startDate") {
        if (Date.parse(this.endDate) < Date.parse(value)) {
          this.SnackbarShow("endDateValidation");
        }
      } else {
        if (Date.parse(value) < Date.parse(this.startDate)) {
          this.SnackbarShow("endDateValidation");
        }
      }
      return false;
      // if (Date.parse(this.endDate) < Date.parse(this.startDate)) {
      //   this.SnackbarShow('endDateValidation')
      //   return false
      // }
    },
    filterDate(value) {
      this.$emit("filterDate", value);
    },
  },
};
</script>

<style lang="scss" scoped>
button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  background-color: #5a5a5a;
  color: white !important;
}

.email_read_only_modal .v-input__control .v-input__slot {
  background-color: #ededed;
}

.v-btn:before {
  background-color: #ffffff !important;
}

.v-text-field input {
  /* padding-top: 8px !important; */
  margin-top: 5px !important;
}

/* .member_text_field_slot v-input__control v-input__slot v-text-field__slot  input{
padding-top: 8px 0 8px !important;
} */
.list {
  max-width: 750px;
}

.datacountClass {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* .v-btn {
min-width: 100px;
} */

.admin_dashbord_content_member_list {
  /* margin-top: 3px !important; */
}

.v-input__prepend-outer {
  margin-top: 0px !important;
}

.v-input__append-inner {
  margin-top: 6px !important;
}

.v-text-field input {
  padding-top: 3px !important;
}

table th span {
  font-size: 14px !important;
  font-family: "Noto Sans" !important;
  color: black;
}

.v-data-table-header {
  background-color: #d9d9d9;
}

// .admin_dashbord_content_member_list_row {
//   // margin-top: -18px !important;
//   margin-left: 5px !important;
// }

// .admin_dashbord_content_member_list_row_first {
//   margin-left: 5px !important;
// }

.vcardaction_buttons {
  margin: 15px 247px !important;
  /* justify-content: center !important; */
}

.vcardaction_buttons_withdelete {
  margin: 15px 174px !important;
}

.v_dialog_member {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: "Noto Sans" !important;
}

li {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: "Noto Sans" !important;
}

.approval_toggle_button {
  /* position: absolute !important; */
  // width: 849.31px !important;

  background: #ffffff !important;
}

.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  max-height: 90%;
}

.v-select__slot {
  max-height: 36px !important;
  display: flex !important;
  align-items: center !important;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  width: 122px;
}

.approval_toggle_button button {
  width: 20% !important;
}

.approval_toggle_button button span {
  // color: black;
  word-break: break-all !important;
}

.v-btn__content {
  /* word-wrap: break-word; */
}

.name_details_span li {
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .view_license_dialog {
    min-width: fit-content !important;
  }
}

.view_license_main_footer {
  justify-content: center !important;
  text-align: center;
}

.view_license_main_footer button {
  background-color: #d9d9d9;
  border-radius: 0%;
  border: none;
  width: 150px;
  height: 45px;
  color: black;
}

ul.view_license_dialog
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn {
  min-width: 230px;
  margin: 5px;
  /* border: 1px solid black !important; */
  // border-radius: 6px;
}

.approval_toggle_button.v-item-group.theme--light.v-btn-toggle {
  display: block;
}

@media only screen and (max-width: 992px) {
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    /* changed in button group */
    // display: block;
  }

  .v-application .d-inline-block {
    display: inline-block !important;
    word-break: break-word;
    width: 50%;
    float: left;
  }

  .v-card__text.v_dialog_member {
    overflow-x: hidden;
  }

  .view_license_dialog.list-group.list-group-horizontal {
    width: 100%;
    float: left;
  }
}

.dialog_member_row_test {
  margin: -22px 0px !important;
}

@media screen and (max-width: 487px) {
  .view_details_close_btn {
    margin-top: 2% !important;
  }
}

@media screen and (max-width: 599px) {
  .admin_dashbord_content_member_second_list {
    margin-top: -35px !important;
  }

  .inquiry_btn_button_main {
    margin-top: -40px;
  }

  .dialog_member_col_test {
    margin: 4px !important;
    padding: 0px !important;
  }

  .dialog_member_row_test_down {
    margin: 0px !important;
    padding: 4px !important;
  }
}

@media screen and (max-width: 960px) {
  .date_time_week_btngroup_main_class {
    margin-top: -41px !important;
  }
}

@media screen and (min-width: 960px) {
  .date_time_week_btngroup_toggle {
    display: block !important;
  }

  .date_time_week_btngroup {
    padding-top: -20px;
  }
}

@media screen and (max-width: 555px) {
  .row .admin_dashbord_content_member_list_row_first {
    display: block;
    .col {
      width: 100%;
      max-width: 100%;
      padding: 0 12px;
    }
    .col-4 {
      padding-bottom: 15px;
      .v-card {
        border-top: 0;
      }
    }
  }

  .admin_dashbord_content_member_list_row:last-child {
    display: block;
    .col {
      width: 100%;
      max-width: 100%;
      padding: 0 12px;
    }
  }
  div.admin_dashbord_content_member_list {
    width: 50%;
    max-width: 50%;
    &.admin_dashbord_content_member_second_list {
      margin-top: 0 !important;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot {
      font-size: 14px;
      padding: 0 8px;
    }
  }
  div.date_time_week_btngroup_main_class {
    margin-top: -20px !important;
    margin-bottom: 20px;
    .v-btn {
      font-size: 11px;
    }
  }
  .date_time_week_btngroup_toggle {
    display: block !important;
  }
}

// .menuable__content__active{
//   width: 122px;
// }

.date_time_week_btngroup_toggle {
  // border: 1px solid black;
}

img {
  vertical-align: middle;
  border: 0;
}

.startdate_picker {
  width: 290px !important;
}

.div_border_top {
  border-top: 1px solid;
  padding-top: 10px;
}
</style>
