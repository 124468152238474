import http from '../http-common'
// const axios = require('axios')

class StatisticsDataService {
  getSignUpCount ({ startDate, endDate }) {
    return http.get(`/api/members/signUpCount?startDate=${startDate}&endDate=${endDate}`)
  };

  getLoginCount ({ startDate, endDate }) {
    return http.get(`/api/members/logInCount?startDate=${startDate}&endDate=${endDate}`)
  };
}

export default new StatisticsDataService()
