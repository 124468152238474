export const statisticTable = {
  signUp: {
    title: '회원가입 통계',
    allCount: '총 가입자 수',
    toDayCount: '오늘의 가입자 수',
  },
  login: {
    title: '회원로그인 통계',
    allCount: '총 로그인 수',
    toDayCount: '오늘의 로그인 수',
  },
}
